import React from "react"
import { Handle, Position } from "reactflow"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { useWorkflow } from "./workflowContext"

export const inquirySubmittedTaskCardNode = ({ data }) => {
  const { cardDimensions } = useWorkflow()
  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
  }
  const handleStyles = getVisualBuilderHandleStyles("right")
  const newHandleStyles = {
    top: cardDimensions.height / 2,
    ...handleStyles,
  }

  const detailPair = (key, value, image = null) => {
    return (
      <div className="flex justify-between space-x-2">
        <div className="body-text text-gray-500">{key}</div>
        <div className={`body-text flex space-x-1 items-center text-right ${value ? "" : "text-gray-400"}`}>
          {image && <div dangerouslySetInnerHTML={{ __html: image }} />}
          <div>{value || notSet}</div>
        </div>
      </div>
    )
  }

  const renderSupplierNames = (supplierNames: string[]) => {
    if (supplierNames && supplierNames.length > 0) {
      const supplierLabel = supplierNames.length > 1 ? "Suppliers" : "Supplier"
      return (
        <div className="flex justify-between space-x-2">
          <div className="body-text text-gray-500">{supplierLabel}</div>
          <div className="body-text flex space-x-1 items-center text-right">{supplierNames.join(", ")}</div>
        </div>
      )
    }
  }

  const renderDepartmentName = (department: string) => {
    if (department && department !== "") {
      return detailPair("Department", department)
    }
  }

  const NotSet = () => <span className="body-text text-gray-400">Not Set</span>
  const notSet = <NotSet />

  return (
    <div
      id={`request-task-card-${data.taskId}`}
      className="p-4 border border-gray-300 rounded-md bg-gray-50"
      style={containerStyles}
    >
      <Handle type="source" position={Position.Right} style={newHandleStyles} />
      <div className="flex flex-col space-y-3">
        <div className="flex flex-row">
          <div className="truncate small-heading">Request Submitted</div>
        </div>
        {detailPair("Category", data.category)}
        {renderSupplierNames(data.supplierNames)}
        {renderDepartmentName(data.department)}
        {detailPair("Requestor", data.requestorName, data.requestorAvatar)}
        {detailPair("Submitted On", data.submittedOn)}
      </div>
    </div>
  )
}

export default inquirySubmittedTaskCardNode
