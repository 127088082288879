import { Controller } from "@hotwired/stimulus"
import { hide } from "../utils"

// Connects to data-controller="upgrade"
export default class extends Controller {
  static targets = ["upgradeSection", "hideButton"]

  upgradeSectionTarget: HTMLDivElement
  hideButtonTarget: HTMLDivElement

  connect() {
    this.hideButtonTarget.addEventListener("click", this.onClick)
  }

  disconnect(): void {
    this.hideButtonTarget.removeEventListener("click", this.onClick)
  }

  onClick = (event) => {
    event.preventDefault()

    hide(this.upgradeSectionTarget)
  }
}
