import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="inquiry-categories--template-select"
export default class extends Controller {
  static targets = ["templateTypeSelect", "inquiryCategorySelect"]

  templateTypeSelectTarget: HTMLSelectElement
  inquiryCategorySelectTarget: HTMLSelectElement

  onTemplateTypeChange(): void {
    const templateType = this.templateTypeSelectTarget.value

    if (templateType === "existing") {
      show(this.inquiryCategorySelectTarget)
    } else {
      hide(this.inquiryCategorySelectTarget)
    }
  }
}
