import { Chart } from "chart.js"
import { Controller } from "@hotwired/stimulus"
import { template } from "lodash"
import { hide, show } from "../utils"

/*
 This is a wrapper around chart.js that strives to be as small as possible. It is designed to work in conjunction
 with the Charts::ChartComponent view component, which is responsible for supplying the bulk of the necessary
 chart.js configuration. Only those things that _must_ happen in javascript are done here — wiring up event handlers,
 or formatting callbacks.

 Events are converted into stimulus events that can be registered to stimulus actions without, hopefully, needing to
 modify this code at all.
 */

// Connects to data-controller="chart-component"
export default class ChartComponentController extends Controller {
  /**
   * {{}} for eval
   * {{= }} for interpolation
   */
  static templateConfig = {
    evaluate: /\{\{([\s\S]+?)\}\}/g,
    interpolate: /\{\{=([\s\S]+?)\}\}/g,
    escape: /\{\{-([\s\S]+?)\}\}/g,
  }

  static targets = [
    "canvas",
    "config",
    "tooltip",
    "tooltipBodyTemplate",
    "tooltipContent",
    "tooltipFooterTemplate",
    "tooltipHeaderTemplate",
    "wrapper",
    "yAxisTemplate",
  ]
  canvasTarget: HTMLCanvasElement
  configTarget: HTMLScriptElement
  tooltipBodyTemplateTarget: HTMLTemplateElement
  tooltipContentTarget: HTMLDivElement
  tooltipFooterTemplateTarget: HTMLTemplateElement
  tooltipHeaderTemplateTarget: HTMLTemplateElement
  tooltipTarget: HTMLDivElement
  wrapperTarget: HTMLElement

  hasTooltipBodyTemplateTarget: boolean
  hasTooltipHeaderTemplateTarget: boolean
  hasTooltipFooterTemplateTarget: boolean
  hasTooltipTarget: boolean

  static values = { currency: String }
  currencyValue: string

  chart: Chart
  tooltip: HTMLDivElement
  tooltipHeaderTemplate: (data: any) => string
  tooltipBodyTemplate: (data: any) => string
  tooltipFooterTemplate: (data: any) => string
  tooltipOffsets: { x: number; y: number }

  onClick(_event, elements) {
    this.dispatch("click", {
      detail: { elements, datapoints: this.extractDatapoints(elements) },
    })
  }

  onHover(_event, elements) {
    this.dispatch("hover", {
      detail: { elements, datapoints: this.extractDatapoints(elements) },
    })
  }

  extractDatapoints(elements) {
    return elements.map(({ element }) => {
      return { ...element.$context.raw, _context: element.$context }
    })
  }

  withCurrency(value) {
    if (!this.currencyValue) {
      return value
    }

    return value.toLocaleString("en-US", {
      style: "currency",
      currency: this.currencyValue,
      maximumFractionDigits: 0,
    })
  }

  tooltipHandler({ tooltip, chart: { canvas } }) {
    if (tooltip.opacity === 0) {
      return hide(this.tooltipTarget)
    }

    this.tooltipContentTarget.innerHTML = this.renderTooltip(tooltip)
    this.positionTooltip(tooltip, canvas)

    show(this.tooltipTarget)
  }

  // returns the sum of the datapoint values with the given key or the parsed x/y values
  // note that only the datapoints passed to the tooltip are available, which is based on the interaction mode
  // nearest, which renders a tooltip for just a single chunk of a stacked bar, for example, only gets the one
  // datapoint, rather than all in the stack
  sumOf(datapoints, key) {
    const values = datapoints.map((datapoint) => {
      if (key === "parsed.x") {
        return datapoint.parsed.x
      } else if (key === "parsed.y") {
        return datapoint.parsed.y
      } else {
        return datapoint.raw[key]
      }
    })
    return values.reduce((acc, val) => acc + val, 0)
  }

  renderTooltip(tooltip) {
    const parts = [
      this.renderTooltipHeader(tooltip),
      this.renderTooltipBody(tooltip),
      this.renderTooltipFooter(tooltip),
    ]
    return parts.join("")
  }

  commonTooltipVars(tooltip) {
    return {
      _chart: tooltip.chart,
      _currency: this.currencyValue,
      sumOf: this.sumOf.bind(this, tooltip.dataPoints), // curry this helper function for use in the template
      withCurrency: this.withCurrency.bind(this), // expose this function to the template
    }
  }

  renderTooltipHeader(tooltip) {
    if (!this.tooltipHeaderTemplate) {
      return
    }
    return this.tooltipHeaderTemplate({
      ...this.commonTooltipVars(tooltip),
      title: tooltip.title,
    })
  }

  renderTooltipBody(tooltip) {
    if (!this.tooltipBodyTemplate) {
      return
    }
    // some interaction modes (such as interacting with vertical lines) might return multiple data points
    // in these cases, the template is rendered for each one and the results are joined
    return tooltip.dataPoints
      .map((datapoint, idx) => {
        return this.tooltipBodyTemplate({
          ...datapoint.raw, // expand the raw dataset values for use in the template
          ...this.commonTooltipVars(tooltip),
          _label: datapoint.label,
          _value: datapoint.formattedValue,
          _datasetLabel: datapoint.dataset.label,
          _color: tooltip.labelColors[idx].backgroundColor,
        })
      })
      .toReversed()
      .join("")
  }

  renderTooltipFooter(tooltip) {
    if (!this.tooltipFooterTemplate) {
      return
    }
    return this.tooltipFooterTemplate({
      ...this.commonTooltipVars(tooltip),
      title: tooltip.title,
    })
  }

  positionTooltip(tooltip, canvas) {
    const { x: xOffset, y: yOffset } = this.tooltipOffsets
    this.tooltipTarget.style.left = `${xOffset + tooltip.caretX}px`
    this.tooltipTarget.style.top = `${yOffset + tooltip.caretY}px`
  }

  parseConfig() {
    const config = JSON.parse(this.configTarget.text)

    config.options.onClick = this.onClick.bind(this)
    config.options.onHover = this.onHover.bind(this)

    if (typeof config?.options?.scales?.y?.ticks === "object") {
      config.options.scales.y.ticks.callback = this.withCurrency.bind(this)
    }

    if (this.hasTooltipTarget) {
      config.options.plugins.tooltip.external = this.tooltipHandler.bind(this)
    }

    return config
  }

  calculateTooltipOffsets() {
    const { left: canvasLeft, top: canvasTop } = this.canvasTarget.getBoundingClientRect()
    const { left: wrapperLeft, top: wrapperTop } = this.wrapperTarget.getBoundingClientRect()
    this.tooltipOffsets = { x: canvasLeft - wrapperLeft, y: canvasTop - wrapperTop }
  }

  connect() {
    if (this.hasTooltipTarget) {
      if (this.hasTooltipHeaderTemplateTarget) {
        this.tooltipHeaderTemplate = template(
          this.tooltipHeaderTemplateTarget.innerHTML,
          ChartComponentController.templateConfig,
        )
      }
      if (this.hasTooltipBodyTemplateTarget) {
        this.tooltipBodyTemplate = template(
          this.tooltipBodyTemplateTarget.innerHTML,
          ChartComponentController.templateConfig,
        )
      }
      if (this.hasTooltipFooterTemplateTarget) {
        this.tooltipFooterTemplate = template(
          this.tooltipFooterTemplateTarget.innerHTML,
          ChartComponentController.templateConfig,
        )
      }
      this.calculateTooltipOffsets()
    }

    const config = this.parseConfig()
    this.chart = new Chart(this.canvasTarget, config)
  }

  disconnect() {
    this.chart.destroy()
  }

  rerender() {
    this.chart.destroy()
    const config = this.parseConfig()
    config.options.animation = false
    this.chart = new Chart(this.canvasTarget, config)
  }
}
